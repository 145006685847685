function dropdownHoverIntent() {
    $(function () {
        var config = {
            sensitivity: 3, // number = sensitivity threshold (must be 1 or higher)    
            interval: 200, // number = milliseconds for onMouseOver polling interval    
            over: doOpen, // function = onMouseOver callback (REQUIRED)    
            timeout: 200, // number = milliseconds delay before onMouseOut    
            out: doClose // function = onMouseOut callback (REQUIRED)    
        };

        function doOpen() {
            if ($(this).hasClass('main_li')) {
                if ($(this).find('.line:first').length) {
                    $(this).find('.line:first').addClass("visible");
                }

                if ($(this).children().length === 3) {
                    $("#black-overlay").addClass('visible')
                }
            }

            if ($(this).hasClass('sub_li')) {
                if ($(this).children().find('.line2:first').length) {
                    $(this).children().find('.line2:first').addClass("visible");
                }

                if ($(this).children().children().length === 2) {
                    $(this).parent().find('.separator').addClass('visible')
                    $(this).children().find("ul:first").find("li.dynamic:first").find('a:first').text($(this).find("a:first").text())
                    $(this).children().find("ul:first").css('top', -$(this).index() * 27)
                }
            }

            $(this).addClass("hover");
            $('ul:first', this).css('visibility', 'visible');
        }

        function doClose() {
            if ($(this).children().length === 3) {
                $("#black-overlay").removeClass('visible')
            }

            if ($(this).hasClass('main_li')) {
                if ($(this).find('.line:first').length === 1) {
                    $(this).find('.line:first').removeClass("visible");
                }
            }

            if ($(this).hasClass('sub_li')) {
                if ($(this).children().find('.line2:first').length === 1) {
                    $(this).children().find('.line2:first').removeClass("visible");
                }

                if ($(this).children().children().length === 2) {
                    $(this).parent().find('.separator').removeClass('visible')
                    $(this).find('.line2:first').removeClass("visible");
                    $(this).children().find("ul:first").find("li.dynamic:first").find('a:first').text('')
                }
            }

            $(this).removeClass("hover");
            $('ul:first', this).css('visibility', 'hidden');
        }

        $("ul.dropdown li").hoverIntent(config);
    });
}